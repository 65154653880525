import React, { useState } from 'react';
import PrimaryButton from '../components/PrimaryButton';
import Spinner from '../components/Spinner';
import { ApiHandler } from '../services/apiHandler';

const Analytics: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'dashboard' | 'query'>('dashboard');
  const [query, setQuery] = useState<string>('');
  const [data, setData] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleQuerySubmit = async () => {
    try {
      setError(null);
      setLoading(true);
      ApiHandler({ apiName: 'analyticsParams', body: { query: query }, params: {} })
        .then((response: any) => {
          setData(JSON.parse(response.data.data));
          setLoading(false);
        })
        .catch((error: any) => {
          console.log(error);
          setError('Failed to fetch data. ' + (error.data.message ?? error.message ?? ''));
          setLoading(false);
        });
    } catch (err) {
      setError('Failed to fetch data');
      setLoading(false);
    }
  };

  return (
    <div className="overflow-auto d-flex flex-column w-100 h-100" style={{ padding: '20px' }}>
      <ul className="nav mt-3">
        <li
          id="dashboard"
          onClick={() => {
            setActiveTab('dashboard');
          }}
          className={`${activeTab === 'dashboard' ? 'active' : ''}`}>
          {'DASHBOARD'}
        </li>

        <li
          id="query"
          onClick={() => {
            setActiveTab('query');
          }}
          className={`${activeTab === 'query' ? 'active' : ''}`}>
          {'QUERY'}
        </li>
      </ul>

      {activeTab === 'dashboard' ? (
        <></>
      ) : (
        <div className="mt-2">
          <textarea
            className="w-100 p-2"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            rows={2}
            placeholder="Enter your SQL SELECT query here"
          />
          <br />
          <div className="d-flex flex-row justify-content-end">
            <PrimaryButton label="Execute Query" clicked={handleQuerySubmit} />
          </div>

          {error && <p style={{ color: 'red' }}>{error}</p>}

          {data.length > 0 && (
            <table border={1} className="m-3 p-2 w-100 h-100 overflow-auto">
              <thead>
                <tr>
                  {Object.keys(data[0]).map((key) => (
                    <th className="p-1" style={{ border: '1px solid black' }} key={key}>
                      {key}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    {Object.values(row).map((value: any, i: any) => (
                      <td className="p-1" style={{ border: '1px solid black' }} key={i}>
                        {value}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}

      <Spinner show={loading} />
    </div>
  );
};

export default Analytics;
